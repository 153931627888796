import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as amplitude from '@amplitude/analytics-browser';
//import reportWebVitals from './reportWebVitals';
import { useEffect } from 'react';

// import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import { BrowserRouter } from 'react-router-dom' ;
import { firebaseDb, firebaseAuth } from './firebase.js';


// Initialize Amplitude
amplitude.init('9d0982511c395fa22bddd4a054c5b733');

// Set user id
const AppWrapper = () => {
  useEffect(() => {
    // Set user ID if available
    firebaseAuth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        
        amplitude.setUserId(user.uid);
        console.log('logged in!' + user.uid);
      }
    });

     // Log an event once
     amplitude.track('event_name', {}, (response) => {
      if (response.code !== 200) {
        console.error('Amplitude event failed:', response);
      }
    });
  }, []);

  return <App />;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
