import React, { Component, useState } from 'react';
import { ToggleButton, Button, ToggleButtonGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { firebaseAuth } from './firebase.js';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css';
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import redCheck from './lotties/greencheck.json';
import "react-toggle/style.css"; // for ES6 modules

import Navigation from './Navigation';
import FreeTrialAlert from './components/FreeTrialAlert';
import ConfirmScreen from './components/ConfirmScreen.js';
import { isNullUndefBlank } from './util/Util';


const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

export default function AddTransaction(props) {
    //   const history = useHistory();
    let navigate = useNavigate();

    const [userID, setUserID] = useState('');
    const [email, setEmail] = useState('');
    const [newAmount, setNewAmount] = useState(0);
    const [newAmtToggle, setNewAmtToggle] = useState(false);
    // 0 = income, 1 = expense, 2 = transfer
    const [newType, setNewType] = useState(1);
    const [newXfr, setNewXfr] = useState(0);
    const [newPayee, setNewPayee] = useState('');
    const [newCat, setNewCat] = useState('');
    const [newAcc, setNewAcc] = useState('');
    const [XfrToAcc, setXfrToAcc] = useState('');
    const [XfrFromAcc, setXfrFromAcc] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [split, setSplit] = useState(false);
    const [splitList, setSplitList] = useState([['', 0], ['', 0]]);
    const [accOptions, setAccOptions] = useState([]);
    const [token, setToken] = useState('');
    const [pretax, setPretax] = useState(false);
    const [pretaxList, setPretaxList] = useState([['401k Contribution', 0], ['401k Match', 0]]);
    const [days_left, setDaysLeft] = useState(600);
    const [isPayeeBlank, setIsPayeeBlank] = useState(false);
    const [isCategoryBlank, setIsCategoryBlank] = useState(false);
    const [isAccountBlank, setIsAccountBlank] = useState(false);
    const [isTransToBlank, setIsTransToBlank] = useState(false);
    const [isTransFromBlank, setIsTransFromBlank] = useState(false);
    const [isSplitListBlank, setIsSplitListBlank] = useState(false);
    


    var curr = new Date();
    curr.setDate(curr.getDate() - 1);
    var date = curr.toISOString().substr(0, 10);
    const [newDate, setNewDate] = useState(date);

    const toCurrency = (x) => {
        // *** Convert to format $0.00
        return (x / 100).toLocaleString('US', {
            style: "currency",
            currency: "USD"
        });
    }


    const toNumber = (x) => {
        const newX = 100 * Number(x.replace(/[^0-9.-]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/(?!^)-/g, '')
            .replace(/^0+(\d)/gm, '$1'));
        // console.log('Turning into Number...' + newX);
        return (newX);

    }


    const toggleType = (x) => {
        setTimeout(() => {
            console.log("Toggling the value...", newType, x);
            if (x != newType) {
                console.log("Toggling the value...", newType, x);
                setIsAccountBlank(false);
                setIsCategoryBlank(false);
                setIsPayeeBlank(false);
                setIsTransToBlank(false);
                setIsTransFromBlank(false);
                setNewType(x);
            } else {
                console.log("Doing nothing", newType, x);
            }
        }, 250);
        return (1);
    }

    const toggleXfr = (x) => {
        setTimeout(() => {
            console.log("Toggling the transfer value...");
            setNewXfr(!newXfr);
        }, 250);
        return (1);
    }

    const toggleSplit = () => {
        setTimeout(() => {
            console.log("Toggling the split value...",splitList);
            setIsSplitListBlank(false);
            setSplit(!split);
        }, 250);
        return (1);
    }

    const togglePretax = () => {
        setTimeout(() => {
            console.log("Toggling the pretax value...");
            setPretax(!pretax);
        }, 250);
        return (1);
    }

    function updateAmt(rawVal) {
        const newVal = toNumber(rawVal);
        // this.setState({newAmount: newVal});
        // could introduce a very fake toggle to force state change

        // console.log(newVal);
        // setNewAmount(0);
        setNewAmount(newVal);
        setNewAmtToggle(!newAmtToggle);
        // setState({ newAmount: newVal })
    }

    const attemptSave = (x) => {
        console.log("Checking fields...")
        
        if (split) {
            // IF SPLIT EXPENSE
            // check splitlist
            console.log('Saving split expense');
            if (isNullUndefBlank(newAcc)) {
                console.log("Null account")
                setIsAccountBlank(true);
            }
            if (isNullUndefBlank(newPayee)) {
                console.log("Null newPayee")
                setIsPayeeBlank(true);
            }
            if (splitList.length == 0) {
                console.log("Empty splitList")
                setIsSplitListBlank(true);
            }
            // Check if any split item has an empty categoryid
            const isValid = splitList.every(item => item[0] !== '');
            if (!isValid) {
                console.log("Null splitList")
                setIsSplitListBlank(true);
                return;
            }
            if (!isNullUndefBlank(newAcc) && !isNullUndefBlank(newPayee) && splitList.length > 0) {
                api_add_split_transaction();
                console.log("Saving a split transaction");
                setTimeout(() => {
                    console.log("Toggling the other value...");
                    setConfirmed(!confirmed);
                }, 550);
                return (1);
            }

        } else if (pretax) {
            // IF INCOME
            // check for source
            // check for account
            // check for date -- default to today, but verify?
            console.log('Saving income w/ pretax');
            if (isNullUndefBlank(newAcc)) {
                console.log("Null account")
                setIsAccountBlank(true);
            }
            if (isNullUndefBlank(newCat)) {
                console.log("Null category")
                setIsCategoryBlank(true);
            }
            if (isNullUndefBlank(newPayee)) {
                console.log("Null newPayee")
                setIsPayeeBlank(true);
            }
            if (!isNullUndefBlank(newAcc) && !isNullUndefBlank(newCat) && !isNullUndefBlank(newPayee)) {
                api_add_pretax_transaction();
                console.log("Saving a pretax transaction");
                setTimeout(() => {
                    console.log("Toggling the other value...");
                    setConfirmed(!confirmed);
                }, 550);
                return (1);
            }
        } else {
            if (newType == 2) {
                // IF TRANSFER
                // check for From
                // check for To
                // check for date
                console.log('Saving transfer');
                if (isNullUndefBlank(XfrFromAcc)) {
                    console.log("Null XfrFromAcc")
                    setIsTransFromBlank(true);
                }
                if (isNullUndefBlank(XfrToAcc)) {
                    console.log("Null XfrToAcc")
                    setIsTransToBlank(true);
                }
                if (!isNullUndefBlank(XfrFromAcc) && !isNullUndefBlank(XfrToAcc)) {
                    api_add_transfer();
                    console.log("Saving a transfer");
                    setTimeout(() => {
                        console.log("Toggling the other value...");
                        setConfirmed(!confirmed);
                    }, 550);
                    return (1);
                }
            
            } else {
                // IF REGULAR INCOME OR REGULAR EXPENSE
                console.log("Saving an income")
                console.log(newAcc, newCat, encodeURIComponent(newPayee), Math.round(newAmount), newDate)
                if (isNullUndefBlank(newAcc)) {
                    console.log("Null account")
                    setIsAccountBlank(true);
                }
                if (isNullUndefBlank(newCat)) {
                    console.log("Null category")
                    setIsCategoryBlank(true);
                }
                if (isNullUndefBlank(newPayee)) {
                    console.log("Null newPayee")
                    setIsPayeeBlank(true);
                }
                if (!isNullUndefBlank(newAcc) && !isNullUndefBlank(newCat) && !isNullUndefBlank(newPayee)) {
                api_add_transaction();
                console.log("Saving a transaction");
                setTimeout(() => {
                    console.log("Toggling the other value...");
                    setConfirmed(!confirmed);
                }, 550);
                return (1);
                }
            } 
        }

        
    }

    // console.log("current type is", newType);
    var amtClass = '';
    var payeeLabel = '';
    var payeeHint = '';
    var categoryLabel = '';
    var catOptions = {};
    var saveButtonClass = '';
    var confirmColor = '';
    if (newType == 2) {
        amtClass = "TransactionAmount TransferAmount";
        saveButtonClass = 'col saveButtonYellow saveOrCancelButton';
    } else if (newType == 0) {
        amtClass = "TransactionAmount IncomeAmount";
        payeeLabel = 'Source';
        payeeHint = 'Who paid you?';
        categoryLabel = 'Available';
        // catOptions = ['Next Month', 'This Month'];
        catOptions = [{ type: 'category', categoryid: -2, name: 'Next Month' }, { type: 'category', categoryid: -1, name: 'This Month' }];
        saveButtonClass = 'col saveButtonGreen saveOrCancelButton';
        confirmColor = 'greenCheck';
    } else {
        amtClass = "TransactionAmount ExpenseAmount";
        payeeLabel = 'Payee';
        payeeHint = 'Who did you pay?';
        categoryLabel = 'Category';
        saveButtonClass = 'col saveButtonRed saveOrCancelButton';
        confirmColor = 'redCheck';
    }


    // if number doesn't actually change it won't update!

    const api_get_params = (authToken) => {
        const data = { token: authToken, color2: 'red' };
        const requestOptions = {
            method: 'GET'
        };
        // Where we're fetching data from
        const baseURL = dbURL + 'newTransaction?';
        const reqURL = baseURL + 'token=' + data.token;
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => {
                const newData = data.results.rows
                console.log("params", newData);
                setAccOptions(newData);
                setDaysLeft(newData[0]['days_left']);
            })
            .catch((error) => console.error(error));
    }

    const api_add_transaction = () => {
        const data = {
            token: token, accountid: newAcc, categoryid: newCat,
            merchant: encodeURIComponent(newPayee), notes: '', amount: Math.round(newAmount), occurred_at: newDate,
            effective_at: newDate
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'addtransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + (data.amount).toFixed(0)
            + '&occurred=' + data.occurred_at
            + '&effective=' + data.effective_at;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }


    const api_add_transfer = () => {
        const transferid = uuidv4();
        console.log("UUID", uuidv4());
        const data = {
            token: token, fromaccountid: XfrFromAcc, toaccountid: XfrToAcc,
            amount: newAmount, occurred_at: newDate,
            effective_at: newDate,
            transferid: transferid
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };
        console.log('Adding before transfer api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'addtransfer?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&fromaccountid=' + data.fromaccountid
            + '&toaccountid=' + data.toaccountid
            + '&amount=' + (data.amount).toFixed(0)
            + '&occurred=' + data.occurred_at
            + '&effective=' + data.effective_at
            + '&transferid=' + data.transferid;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_add_split_transaction = () => {
        const splitid = uuidv4();
        console.log("UUID", uuidv4());
        let promiseList = [];
        splitList.forEach((splitItem) => {
            // call api
            const data = {
                token: token,
                accountid: newAcc,
                categoryid: splitItem[0],
                merchant: encodeURIComponent(newPayee),
                notes: '',
                amount: Math.round(toNumber((splitItem[1]).toString())),
                occurred_at: newDate,
                effective_at: newDate,
                splitid: splitid
            };
            const requestOptions = {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            };

            console.log('Adding before api post...');
            // Where we're fetching data from
            const baseURL = dbURL + 'addtransaction?';
            const reqURL = baseURL
                + 'token=' + data.token
                + '&accountid=' + data.accountid
                + '&categoryid=' + data.categoryid
                + '&merchant=' + encodeURIComponent(data.merchant)
                + '&notes=' + data.notes
                + '&amount=' + (data.amount).toFixed(0)
                + '&occurred=' + data.occurred_at
                + '&effective=' + data.effective_at
                + '&splitid=' + data.splitid;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
            // We get the API response and receive data in JSON format
            // .then((response) => response.json())
            // .then((data) => console.log("Adding after api post.." + data))
            // .catch((error) => console.error(error));
        })
        return (Promise.all(promiseList));
    }

    const api_add_pretax_transaction = () => {
        // Generate the shared stuff
        const splitid = uuidv4();
        console.log("UUID", uuidv4());
        let promiseList = [];


        // Need to add the regular income *and then* the pretax items
        const data = {
            token: token, accountid: newAcc, categoryid: newCat,
            merchant: encodeURIComponent(newPayee), notes: '',
            amount: Math.round(newAmount), occurred_at: newDate,
            effective_at: newDate,
            splitid: splitid
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'addtransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + (data.amount).toFixed(0)
            + '&occurred=' + data.occurred_at
            + '&effective=' + data.effective_at
            + '&splitid=' + data.splitid;
        console.log(reqURL);
        const newIncomePromise = fetch(reqURL, requestOptions);
        promiseList.push(newIncomePromise);


        // Add the pre-tax items
        pretaxList.forEach((pretaxItem) => {
            // call api
            // If "Avail Now" --> -6 (Pretax Now)
            // If "Avail Next Month" --> -7 (Pretax Next Month)
            var newPretaxCat = -7
            if (newCat == -1) {
                newPretaxCat = -6
            }
            const data = {
                token: token,
                accountid: newAcc,
                categoryid: newPretaxCat,
                merchant: encodeURIComponent(newPayee),
                notes: encodeURIComponent(pretaxItem[0]),
                amount: Math.round(toNumber((pretaxItem[1]).toString())),
                occurred_at: newDate,
                effective_at: newDate,
                splitid: splitid
            };
            const requestOptions = {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            };

            console.log('Adding before api post...');
            // Where we're fetching data from
            const baseURL = dbURL + 'addtransaction?';
            const reqURL = baseURL
                + 'token=' + data.token
                + '&accountid=' + data.accountid
                + '&categoryid=' + data.categoryid
                + '&merchant=' + encodeURIComponent(data.merchant)
                + '&notes=' + data.notes
                + '&amount=' + (data.amount).toFixed(0)
                + '&occurred=' + data.occurred_at
                + '&effective=' + data.effective_at
                + '&splitid=' + data.splitid;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
            // We get the API response and receive data in JSON format
            // .then((response) => response.json())
            // .then((data) => console.log("Adding after api post.." + data))
            // .catch((error) => console.error(error));
        })
        return (Promise.all(promiseList));
    }

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setUserID(firebaseAuth.currentUser.uid);
                setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
                // const idToken2 = await 
                user.getIdToken()
                    .then(idToken => {  // <------ Check this line
                        api_get_params(idToken);
                        setToken(idToken);
                    });
                console.log('logged in!' + firebaseAuth.currentUser.uid);
                // console.log(api_getIdToken(user));
            } else {
                // No user is signed in.
                console.log('no one signed in');
                navigate('/login');
            }
        });
    }, []);

    class MobileTransactionType extends Component {
        render() {
            return (
                <>
                    <ToggleButtonGroup type="radio" name="radio" id="transTypeGroup">
                        <ToggleButton
                            key={0}
                            id={`radio-`}
                            variant={(newType == 0) ? 'success' : 'outline-success'}
                            value={newType == 0}
                            checked={newType == 0}
                            onClick={(e) => toggleType(0)}
                        >
                            Income
                        </ToggleButton>
                        <ToggleButton
                            key={1}
                            id={`radio-`}
                            variant={(newType == 1) ? 'danger' : 'outline-danger'}
                            value={newType == 1}
                            checked={newType == 1}
                            onClick={(e) => toggleType(1)}
                        >
                            Expense
                        </ToggleButton>
                        <ToggleButton
                            key={2}
                            id={`radio-`}
                            variant={(newType == 2) ? 'warning' : 'outline-warning'}
                            value={newType == 2}
                            checked={newType == 2}
                            onClick={(e) => toggleType(2)}
                        >
                            Transfer
                        </ToggleButton>
                    </ToggleButtonGroup>
                </>
            )
        }
    }

    class DesktopTransactionType extends Component {
        render() {
            return (
                <>
                    <div className='ticketHoles'>
                        <hr className='ticketLine' />
                    </div>


                    <ToggleButtonGroup type="radio" name="radio" id="desktopTransTypeGroup">
                        <ToggleButton
                            key={0}
                            id={`radio-`}
                            variant={(newType == 0) ? 'success' : 'outline-success'}
                            value={newType == 0}
                            checked={newType == 0}
                            onClick={(e) => toggleType(0)}
                        >
                            Income
                        </ToggleButton>
                        <ToggleButton
                            key={1}
                            id={`radio-`}
                            variant={(newType == 1) ? 'danger' : 'outline-danger'}
                            value={newType == 1}
                            checked={newType == 1}
                            onClick={(e) => toggleType(1)}
                        >
                            Expense
                        </ToggleButton>
                        <ToggleButton
                            key={2}
                            id={`radio-`}
                            variant={(newType == 2) ? 'warning' : 'outline-warning'}
                            value={newType == 2}
                            checked={newType == 2}
                            onClick={(e) => toggleType(2)}
                        >
                            Transfer
                        </ToggleButton>
                    </ToggleButtonGroup>

                </>
            )
        }
    }

    function TransactionType() {
        return (
            <>
                <div className='d-none d-lg-block'>
                    <DesktopTransactionType />
                </div>
                <div className='d-block d-lg-none'>
                    <MobileTransactionType />
                </div>
            </>
        );
    }

    class TransactionXfr extends Component {
        render() {
            return (
                <>
                    <div className="container transType">
                        <div className="row">
                            <div className="col-6">
                                <div className="transTypeLabel">Account Transfer</div>
                            </div>
                            <div className="col" id="transXFRToggleDiv">
                                {/* maybe because the state is re-rendering, which doesnt leave time for the transition? */}
                                <Toggle
                                    id='transactionXfr'
                                    defaultChecked={newXfr}
                                    icons={false}
                                    onChange={() => toggleXfr(!newXfr)} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class SplitToggle extends Component {
        render() {
            return (
                <>
                    <div className="container transType mb-3">
                        <div className="row">
                            <div className="col-6">
                                <div className="transTypeLabel">Split Transaction</div>
                            </div>
                            <div className="col" id="transXFRToggleDiv">
                                {/* maybe because the state is re-rendering, which doesnt leave time for the transition? */}
                                <Toggle
                                    id='transactionXfr'
                                    defaultChecked={split}
                                    icons={false}
                                    onChange={() => toggleSplit()} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class PretaxToggle extends Component {
        render() {
            return (
                <>
                    <div className="container transType">
                        <div className="row">
                            <div className="col-6">
                                <div className="transTypeLabel">Pre-Tax Saved</div>
                            </div>
                            <div className="col" id="transXFRToggleDiv">
                                {/* maybe because the state is re-rendering, which doesnt leave time for the transition? */}
                                <Toggle
                                    id='transactionXfr'
                                    defaultChecked={pretax}
                                    icons={false}
                                    onChange={() => togglePretax()} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    function SplitListItem(props) {
        const categoryid = props['categoryid'];
        const amount = props['amount'];
        const index = props['index'];
        // const { categoryid, amount, index, splitList, setSplitList } = props;
        // Log the values being passed to each SplitListItem
        console.log(`Rendering SplitListItem - Index: ${index}, CategoryID: ${categoryid}, Amount: ${amount}`);


        function removeSplitItem(x) {
            let newList = splitList.filter((item, index) => index !== x);
            setSplitList(newList);

        }

        function updateSplitItem(x, cat, amt) {
            console.log("Updating split item", x, cat, amt);
            // let newList = splitList.filter(item => 1 !== 2);
            // newList[x][0] = cat;
            // newList[x][1] = amt;
            // setSplitList(newList);
            let newList = [...splitList];
            newList[x] = [cat, amt];
            setSplitList(newList);

        }

        return (
            <>
                {/* Deskop */}
                <div className='d-none d-lg-block'>
                    <div className="row mt-4 ms-4 mb-4">
                        <div className="col-6">
                            <select id="newTransCat" name="cars" 
                                className={`desktopTransInput ${isSplitListBlank && categoryid === '' ? 'invalid' : ''}`}
                                value={categoryid}
                                onChange={(e) => updateSplitItem(index, e.target.value, amount)}>
                                <option disabled value=''>--select category--</option>
                                {accOptions.filter(d => d.hidden_at == null)
                                    .filter(d => d.type == 'category')
                                    .map((cat, index) => {
                                        return (
                                            <option value={cat.categoryid} key={cat.categoryid}>
                                                {cat.name}
                                            </option>
                                        )
                                    }
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <input className='desktopTransInput w-100' type='number'
                                placeholder='$0.00'
                                defaultValue={amount}
                                onBlur={(e) => updateSplitItem(index, categoryid, e.target.value)}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removeSplitItem(index); }} />
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div className='d-block d-lg-none'>
                    <div className='row mb-4'>
                        <div className="col-7">
                            <select id="newTransCat" name="cars" 
                                className={`transInput ${isSplitListBlank && categoryid === '' ? 'invalid' : ''}`}
                                value={categoryid}
                                onChange={(e) => updateSplitItem(index, e.target.value, amount)}>
                                <option disabled value=''>--select category--</option>
                                {accOptions.filter(d => d.hidden_at == null)
                                    .filter(d => d.type == 'category')
                                    .map((cat, index) => {
                                        return (
                                            <option value={cat.categoryid} key={cat.categoryid}>
                                                {cat.name}
                                            </option>
                                        )
                                    }
                                    )
                                }
                            </select>
                        </div>
                        <div className="col">
                            <input className='splitAmount' type='number'
                                placeholder='$0.00'
                                defaultValue={amount}
                                onBlur={(e) => updateSplitItem(index, categoryid, e.target.value)}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removeSplitItem(index); }} />
                        </div>
                    </div>
                </div>
            </>
        )

    }

    function SplitList() {

        function addSplitItem() {
            console.log("Adding a new split item");
            let newList = splitList.filter(item => 1 !== 2);
            newList.push(['', 0]);
            setSplitList(newList);
        }

        console.log("split list", splitList);
        return (
            <>
                <div className='ticketHoles d-none d-lg-block'>
                    <hr className='ticketLine' />
                </div>
                <div className="container transPayee">

                    {splitList.map((item, index) => {
                        return (
                            <SplitListItem
                                categoryid={item[0]}
                                amount={item[1]}
                                index={index}
                                key={'splitid' + index} />
                        )
                    }
                    )}
                    <div className='btn btn-primary ms-5'
                        onClick={() => { addSplitItem(); }}>
                        + Add category to split
                    </div>
                </div>
            </>
        )
    }

    function PretaxListItem(props) {
        const memo = props['memo'];
        const amount = props['amount'];
        const index = props['index'];


        function removePretaxItem(x) {
            let newList = pretaxList.filter((item, index) => index !== x);
            setPretaxList(newList);

        }

        function updatePretaxItem(x, memo, amt) {
            let newList = pretaxList.filter(item => 1 !== 2);
            newList[x][0] = memo;
            newList[x][1] = amt;
            setPretaxList(newList);

        }

        return (
            <>
                {/* Deskop */}
                <div className='d-none d-lg-block'>
                    <div className="row mt-4 ms-4 mb-4">
                        <div className="col-6">
                            <input className='desktopTransInput w-100' type='text'
                                placeholder='E.g. 401k Contribution'
                                defaultValue={memo}
                                onBlur={(e) => updatePretaxItem(index, e.target.value, amount)}
                            />
                        </div>

                        <div className="col-4">
                            <input className='desktopTransInput w-100' type='number'
                                placeholder='$0.00'
                                defaultValue={amount}
                                onBlur={(e) => updatePretaxItem(index, memo, e.target.value)}
                            />
                        </div>

                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removePretaxItem(index); }} />
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div className='d-block d-lg-none'>
                    <div className='row mb-4'>
                        <div className="col-7">
                            <input className='splitAmount' type='text'
                                placeholder='E.g. 401k Contribution'
                                defaultValue={memo}
                                onBlur={(e) => updatePretaxItem(index, e.target.value, amount)}
                            />
                        </div>
                        <div className="col">
                            <input className='splitAmount' type='number'
                                placeholder='$0.00'
                                defaultValue={amount}
                                onBlur={(e) => updatePretaxItem(index, memo, e.target.value)}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removePretaxItem(index); }} />
                        </div>
                    </div>
                </div>
            </>
        )

    }

    function PretaxList() {

        function addPretaxItem() {
            console.log("Adding a new pretax item");
            let newList = pretaxList.filter(item => 1 !== 2);
            newList.push(['', 0]);
            setPretaxList(newList);
        }

        console.log("pretax list", pretaxList);
        return (
            <>
                <div className="container transPayee">

                    {pretaxList.map((item, index) => {
                        return (
                            <PretaxListItem
                                memo={item[0]}
                                amount={item[1]}
                                index={index}
                                key={'pretax' + index} />
                        )
                    }
                    )}
                    <div className='btn btn-primary ms-5'
                        onClick={() => { addPretaxItem(); }}>
                        + Add category to split
                    </div>
                </div>
            </>
        )
    }

    class TransactionPayee extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-5 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">{payeeLabel}</div>
                            </div>
                            <div className="col">
                                <input 
                                    className={`desktopTransInput w-75 ${isPayeeBlank ? 'invalid' : ''}`}
                                    placeholder={payeeHint}
                                    defaultValue={newPayee}
                                    onBlur={(e) => {
                                        setNewPayee(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsPayeeBlank(false);
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">{payeeLabel}</div>
                            </div>
                            <div className="col">
                                <input 
                                    className={`transInput ${isPayeeBlank ? 'invalid' : ''}`}
                                    placeholder={payeeHint}
                                    defaultValue={newPayee}
                                    onBlur={(e) => {
                                        setNewPayee(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsPayeeBlank(false);
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    class TransactionCategory extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">{categoryLabel}</div>
                            </div>
                            <div className="col-7">
                                <select id="newTransCat" name="cars" 
                                    className={`desktopTransInput w-75 ${isCategoryBlank ? 'invalid' : ''}`}
                                    value={newCat}
                                    onChange={(e) => {
                                        setNewCat(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsCategoryBlank(false);
                                        }
                                        }}>
                                    <option disabled value='' key="op">--select category--</option>
                                    {newType == 0 ? (
                                        catOptions.map((cat, index) => {
                                            return (<option value={cat.categoryid} key={"1" + cat.categoryid}>{cat.name}</option>)
                                        })
                                    ) : (
                                        accOptions.filter(d => d.hidden_at == null)
                                            .filter(d => d.type == 'category')
                                            .map((cat, index) => {
                                                return (
                                                    <option value={cat.categoryid} key={cat.categoryid}>
                                                        {cat.name}
                                                    </option>
                                                )
                                            }
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col-4">
                                <div className="transTypeLabel">{categoryLabel}</div>
                            </div>
                            <div className="col">
                                <select id="newTransCat" name="cars" 
                                    className={`transInput ${isCategoryBlank ? 'invalid' : ''}`}
                                    value={newCat}
                                    onChange={(e) => {
                                        setNewCat(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsCategoryBlank(false);
                                        }
                                    }}>
                                    <option disabled value=''>--select category--</option>
                                    {newType == 0 ? (
                                        catOptions.map((cat, index) => {
                                            return (<option value={cat.categoryid} key={cat.categoryid}>{cat.name}</option>)
                                        })
                                    ) : (
                                        accOptions.filter(d => d.hidden_at == null)
                                            .filter(d => d.type == 'category')
                                            .map((cat, index) => {
                                                return (
                                                    <option value={cat.categoryid} key={cat.categoryid}>
                                                        {cat.name}
                                                    </option>
                                                )
                                            }
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionAccount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">Account</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" 
                                    className={`desktopTransInput w-75 ${isAccountBlank ? 'invalid' : ''}`}
                                    value={newAcc}
                                    onChange={(e) => {
                                        setNewAcc(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsAccountBlank(false);
                                        }
                                    }}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.filter(d => d.hidden_at == null)
                                        .filter(d => d.type == 'account')
                                        .map((acc, index) => {
                                            return (
                                                <option value={acc.categoryid} key={acc.categoryid}>
                                                    {acc.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col-4">
                                <div className="transTypeLabel">Account</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className={`transInput ${isAccountBlank ? 'invalid' : ''}`}
                                    value={newAcc}
                                    onChange={(e) => {
                                        setNewAcc(e.target.value)
                                        if (!(isNullUndefBlank(e.target.value))) {
                                            setIsAccountBlank(false);
                                        }
                                    }}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.filter(d => d.hidden_at == null)
                                        .filter(d => d.type == 'account')
                                        .map((acc, index) => {
                                            return (
                                                <option value={acc.categoryid} key={acc.categoryid}>
                                                    {acc.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }



    class TransactionXFRAccount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-5 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">From</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className={`desktopTransInput w-75 ${isTransFromBlank ? 'invalid' : ''}`}
                                    value={XfrFromAcc}
                                    onChange={(e) => setXfrFromAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">To</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className={`desktopTransInput w-75 ${isTransToBlank ? 'invalid' : ''}`}
                                    value={XfrToAcc}
                                    onChange={(e) => setXfrToAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">From</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className={`transInput ${isTransFromBlank ? 'invalid' : ''}`}
                                    value={XfrFromAcc}
                                    onChange={(e) => setXfrFromAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">To</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" 
                                    className={`transInput ${isTransToBlank ? 'invalid' : ''}`}    
                                    value={XfrToAcc}
                                    onChange={(e) => setXfrToAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionDate extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5 mb-4">
                            <div className="col-3">
                                <div className="transTypeLabel">Date</div>
                            </div>
                            <div className="col">
                                <input className='desktopTransInput w-75' type='date'
                                    value={newDate}
                                    onChange={(e) => setNewDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">Date</div>
                            </div>
                            <div className="col">
                                <input className='transInput' type='date'
                                    value={newDate}
                                    onChange={(e) => setNewDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionAmount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <input type="text"
                            className={amtClass + ' desktopAmount'}
                            defaultValue={toCurrency(newAmount)}
                            aria-label="Amount (to the nearest dollar)"
                            onBlur={e => {
                                updateAmt(e.target.value);
                                // const newId = itemId.slice(4,);
                                // const oldVal = items[newId]['val']['budget'];
                                // // console.log(oldVal);
                                // updateBudgetAmt(newId, e.target.value, oldVal);
                            }}
                        />
                    </div>
                    {/* Mobile */}
                    <div className='d-block d-lg-none'><input type="text"
                        className={amtClass}
                        defaultValue={toCurrency(newAmount)}
                        aria-label="Amount (to the nearest dollar)"
                        onBlur={e => {
                            updateAmt(e.target.value);
                            // const newId = itemId.slice(4,);
                            // const oldVal = items[newId]['val']['budget'];
                            // // console.log(oldVal);
                            // updateBudgetAmt(newId, e.target.value, oldVal);
                        }}
                    // onChange={e => {
                    //     const newVal = toNumber(e.target.value);
                    //     setNewAmount(newVal);
                    // }}
                    />
                    </div>
                </>
            )
        };
    }

    class SaveOrCancel extends Component {
        render() {
            // const history = useHistory();
            return (
                <>
                    {/* Desktop */}
                    {/* Mobile */}
                    <div className="container d-block d-lg-none">
                        <div className="row fixed-bottom text-center">
                                {this.props.is_disabled ? (
                                    // If subscription is over, saving is disabled
                                    <div>
                                    </div>
                                ) : (
                                    <div className={saveButtonClass} onClick={() => attemptSave()}>
                                        SAVE
                                    </div>
                                )}
                            <div className="col cancelButton saveOrCancelButton" onClick={() => navigate(-1)}>
                                CANCEL
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    class DesktopSaveOrCancel extends Component {
        render() {
            // const history = useHistory();
            return (
                <>{/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row justify-content-center">
                            <div className='col-4'>
                                {this.props.is_disabled ? (
                                    // If subscription is over, saving is disabled
                                    <div className="desktopSaveButtonDisabled">
                                        Save Disabled
                                    </div>
                                ) : (
                                    <div className="desktopSaveButton"
                                        onClick={() => attemptSave()}>
                                        Save Transaction
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                </>
            )
        }
    }

    
    class XfrScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionXFRAccount />
                    <TransactionDate />
                    {/* <SplitToggle /> */}
                    {/* <TransactionXfr /> */}
                    <SaveOrCancel is_disabled={this.props.is_disabled}/>
                </>
            )
        };
    }

    class SplitExpenseScreen extends Component {
        render() {
            return (
                <>
                    <TransactionPayee />
                    <TransactionAccount />
                    <TransactionDate />
                    <SplitToggle />
                    <SplitList />
                    <SaveOrCancel is_disabled={this.props.is_disabled}/>
                </>

            )
        }
    }

    class PretaxIncomeScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionPayee />
                    <TransactionCategory />
                    <TransactionAccount />
                    <TransactionDate />
                    <PretaxToggle />

                    <PretaxList />

                    <SaveOrCancel is_disabled={this.props.is_disabled}/>
                </>

            )
        }
    }

    class IncomeOrExpenseScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionPayee />
                    <TransactionCategory />
                    <TransactionAccount />
                    <TransactionDate />
                    {newType == 0 ? (
                        <PretaxToggle />
                    ) : (
                        <SplitToggle />
                    )}

                    <SaveOrCancel is_disabled={this.props.is_disabled}/>
                </>

            )
        }
    }


    // useEffect(() => {
    //     console.log('hey! were updating now!');
    //     console.log(newType);
    // }, []);

    return (
        <>
            <FreeTrialAlert days_left={days_left} email={email}/>
            {/* <div style={days_left === '-1' || days_left < 500
                ? { marginTop: '80px' }
                : {}}> */}
                <div>
                <Navigation key="nav-bar-key" />
                {confirmed ? (
                    <ConfirmScreen
						buttonList={[['Add Another Transaction', ''], ['Back to Budget', '/Budget']]}
					/>
                ) : (
                    <>{/* Desktop */}
                        <div className='d-none d-lg-block greyBG'>
                            <div className='contentSpace ms-5'>
                                <div className='row ms-2'>
                                    <div className='col-10 mt-5 text-black-50 fw-bold'>
                                        <FontAwesomeIcon icon={faArrowLeft}
                                            className="me-3 hoverPointer" />
                                        <span className='hoverPointer'
                                            onClick={() => navigate(-1)}>
                                            Cancel
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center mt-5'>
                                <div id="ticketDiv" className='ticket col'>
                                    <div class="ticket-content-wrapper">
                                        {newType == 2 ? (
                                            // 0=income, 1=expense, 2=transfer
                                            <XfrScreen is_disabled={days_left === '-1'}/>
                                        ) : (
                                            (split && newType == 1) ? (
                                                // Income or Expense w/ split
                                                <SplitExpenseScreen is_disabled={days_left === '-1'}/>
                                            ) : (
                                                (pretax && newType == 0) ? (
                                                    <PretaxIncomeScreen is_disabled={days_left === '-1'}/>
                                                ) : (
                                                    <IncomeOrExpenseScreen is_disabled={days_left === '-1'}/>
                                                )
                                            )

                                        )}


                                        {/* <SplitList /> */}
                                    </div>
                                </div>
                            </div>
                            <DesktopSaveOrCancel is_disabled={days_left === '-1'} />
                        </div>

                        {/* Mobile */}
                        <div className='d-block d-lg-none '>
                            {newType == 2 ? (
                                // {/* // 0=income, 1=expense, 2=transfer */}
                                <XfrScreen is_disabled={days_left === '-1'}/>
                            ) : (

                                (split && newType == 1) ? (
                                    // {/* // Income or Expense w/ split */}
                                    <SplitExpenseScreen is_disabled={days_left === '-1'}/>
                                ) : (
                                    (pretax && newType == 0) ? (
                                        <PretaxIncomeScreen is_disabled={days_left === '-1'}/>
                                    ) : (
                                        <IncomeOrExpenseScreen is_disabled={days_left === '-1'}/>
                                    )
                                )

                            )}

                            <div id="bottomSpacing" className='mb-5'></div>
                        </div>
                    </>
                )
                }
            </div>
        </>
    );
}




