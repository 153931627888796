import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { firebaseAuth } from './firebase.js';
import Toggle from 'react-toggle';
// import useWindowSize from "use-window-size-v2";
// import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import sqarelogo from './img/squarelogo.png';
import { enableLogging } from 'firebase/database';
import GreenLoading from './components/GreenLoading';
import ErrorScreen from './components/ErrorScreen';

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

function ResetBudget(props) {




    let navigate = useNavigate();
    const [resetEnabled, setResetEnabled] = useState(false);
    const [email, setEmail] = useState('MYEMAIL@EMAIL.COM');
    const [code, setCode] = useState('MYEMAIL@EMAIL.COM');
    const [resetting, setResetting] = useState(false);
    const [isErrorResetting, setIsErrorResetting] = useState(false);
    const [userID, setUserID] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    // const [email, setEmail] = useState(null);
    // const [code, setCode] = useState(null);

    // const [searchParams, setSearchParams] = useSearchParams();
    // searchParams.get("email");
    // console.log(searchParams[0]);

    const dbURL = 'https://vermillionapp.fly.dev/';

    const location = useLocation();


    const api_reset_budget = (authToken) => {

        return firebaseAuth.currentUser.getIdToken()
            .then((idToken) => {
                // Send token to your backend via HTTPS
                const data = { token: idToken, color2: 'red' };
                const requestOptions = {
					method: 'POST',
					// headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({})
				};

                // console.log('Adding before api post...');
                // Where we're fetching data from
                const baseURL = dbURL + 'resetBudget?';
                const reqURL = baseURL + 'token=' + data.token;
                console.log(reqURL);
                // return (1);
                return fetch(reqURL, requestOptions)
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error(error);
                        throw error; // Rethrow the error to be caught in the outer promise chain
                    });

            })
            .catch((error) => {
                console.log("No user logged in!");
                throw error;
            });
    }

    const attemptReset = () => {
        setResetting(true);
        api_reset_budget().then((data) => {
            // Check if the returned data contains the expected value
            if (data && data.code === 1) {
                setTimeout(() => {
                    // console.log("Closing modal...");
                    // setCurrEditAccountId('');
                    console.log("done waiting");
                    navigate('/Budget');
                    // console.log("Closing modal...");
                }, 1000);
            } else {
                setIsErrorResetting(true);
            }


        });

    }

    useEffect(() => {
        setIsLoading(true);
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setUserID(firebaseAuth.currentUser.uid);
                setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
                // const idToken2 = await 
                user.getIdToken()
                    .then(idToken => {
                    });
                // console.log('logged in!' + firebaseAuth.currentUser.uid);
            } else {
                // No user is signed in.
                console.log('no one signed in');
                navigate('/login');
            }
        });
    }, []);

    function GoalPage(props) {
        if (isErrorResetting) {
            return <ErrorScreen />;
        }

        if (resetting) {
            return <GreenLoading buttonList={[['Back to Budget', '']]} />
        }

        return (<>
            {/* Desktop */}
            < div className='d-none d-lg-block greyBG' >
                <div className='ms-5 mt-3 hoverPointer'
                    onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeftLong} /> Back
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <div className='app d-flex flex-column p-5'>
                        <div className='w-100 mt-5 align-content-center'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center">
                            <div className='row mt-5 mb-3'>

                                <div className='text-center mb-3 h1' style={{ color: "#3d3a3a" }}>
                                    Ready to clear off the cobwebs?
                                </div>
                                <div className='text-center mb-3 h5' style={{ color: "#3d3a3a" }}>
                                    Click the button below to reset your budget and start fresh!
                                </div>
                            </div>
                            <div className="container">
                                <div className="row mb-1">
                                    <div className="col-1" id="confirmToggleDiv">
                                        <Toggle
                                            id='confirmToggle'
                                            defaultChecked={resetEnabled}
                                            icons={false}
                                            onChange={() => {
                                                setResetEnabled(!resetEnabled);
                                                // setRerender(!rerender);
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="confirmToggleLabel">I understand that:</div>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                {/* <li>The free trial associated with {email} will restart.</li> */}
                                <li>Any accounts and transactions I previously logged will be deleted.</li>
                                <li><b>Deletion is permanent and can't be undone.</b></li>

                            </ul>
                            <div className='row'>
                                <div
                                    // className='button button-darkRed p-2 w-75'
                                    className={`button button-darkRed p-2 w-75 ${!resetEnabled ? 'disabled' : ''}`}
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => {
                                        if (resetEnabled) {
                                            // attemptCloseAcc();
                                            console.log("Reset budget!");
                                            attemptReset();
                                        } else {
                                            console.log("Can't close without confirmation.");
                                        }
                                    }}
                                    disabled={!resetEnabled}
                                >
                                    Reset Budget
                                </div>
                            </div>

                        </div>
                        <br />
                        {/* <ForgotPassword email={email} /> */}
                    </div>
                </div>
            </div >
            {/* Mobile */}
            < div className='d-block d-lg-none ' >
                <div className='ms-4 mt-3 hoverPointer'
                    onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeftLong} /> Back
                </div>
                <div className='app d-flex flex-column'>
                    <div className="d-grid gap-2 mt-auto">

                        <div className='w-100 mt-0 align-content-center mb-5'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className='row mt-2 mb-3'>

                            <div className='text-center mb-3 h1' style={{ color: "#3d3a3a" }}>
                                Ready to clear off the cobwebs?
                            </div>
                            <div className='text-center h5' style={{ color: "#3d3a3a" }}>
                                Click the button below to reset your budget and start fresh!
                            </div>
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center ps-3 pe-3">
                            <div className="container" style={{ width: "fit-content" }}>
                                <div className="row mb-3">
                                    <div className="col-1" id="confirmToggleDiv">
                                        <Toggle
                                            id='confirmToggle'
                                            defaultChecked={resetEnabled}
                                            icons={false}
                                            onChange={() => {
                                                setResetEnabled(!resetEnabled);
                                                // setRerender(!rerender);
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="confirmToggleLabel">I understand that:</div>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                {/* <li>The free trial associated with {email} will restart.</li> */}
                                <li>Any accounts and transactions I previously logged will be deleted.</li>
                                <li><b>Deletion is permanent and can't be undone.</b></li>

                            </ul>
                            <div className='row'>
                                <div
                                    // className='button button-darkRed p-2 w-75'
                                    className={`button button-darkRed p-2 w-50 ${!resetEnabled ? 'disabled' : ''}`}
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => {
                                        if (resetEnabled) {
                                            // attemptCloseAcc();
                                            console.log("Reset budget!");
                                            attemptReset();
                                        } else {
                                            console.log("Can't close without confirmation.");
                                        }
                                    }}
                                    disabled={!resetEnabled}
                                >
                                    Reset Budget
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* <ForgotPassword email={email} /> */}
            </div >


        </>
        )
    }

    return (
        <>
            <GoalPage />
        </>
    );
}

export default ResetBudget;