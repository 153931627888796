export function toCurrency (x) {
    // *** Convert to format $0.00
    return (x / 100).toLocaleString('US', {
        style: "currency",
        currency: "USD"
    });
}

export function  toNumber (x) {
    // console.log("ATTEMPTING UPDATE", x);
    const newX = 100 * Number(String(x).replace(/[^0-9.-]/g, '')
        .replace(/(\..*)\./g, '$1')
        .replace(/(?!^)-/g, '')
        .replace(/^0+(\d)/gm, '$1'));
    // console.log('Turning into Number...' + newX);
    return (newX);
}

export function roundDownOneDecimal (x) {
    const newX = Math.floor(x*10.0) / 10.0;
    return (newX)
}

export function textEllipsis (myText, numChars) {
    var newText;
    if (myText.length <= numChars) {
        newText = myText;
    } else {
        newText = myText.substring(0, numChars-1)+'...';
    }
    return (newText);
}

// export function reformatDate (oldDate) {
//     console.log('old date:',oldDate);
//     var date = new Date(oldDate + 'T00:00:00');
//     return (date.toLocaleString("en-us", {
//         month: "short",
//         day: 'numeric',
//         year: 'numeric'
//     }).toUpperCase().replace(",", ""));
// }
export function reformatDate (oldDate) {
    if (oldDate.length == 9) {
        oldDate = oldDate.slice(0, 8) + '0' + oldDate.slice(-1);
        var date = new Date(oldDate + 'T00:00:00');
    } else if (oldDate.length > 11) {
        // oldDate = oldDate.slice(0, 8) + '0' + oldDate.slice(-1);
        var date = new Date(oldDate);
    }
    // console.log('input: ' + oldDate);
    // console.log('new date: ' + date);
    // console.log('NEW date: ' + date.toLocaleString("en-us", {
    //     month: "short",
    //     day: 'numeric',
    //     year: 'numeric'
    // }).toUpperCase().replace(",", ""));
    return (date.toLocaleString("en-us", {
        month: "short",
        day: 'numeric',
        year: 'numeric'
    }).toUpperCase().replace(",", ""));
}

export function arrayAvg (arr) {
    console.log(arr);
    const len = arr.length;
    
    if (len !== 0 && len !== undefined) {
        const total = arr.reduce((a,b) => parseFloat(a)+parseFloat(b));
        console.log((1.0*total)/(1.0*len))
        return ((1.0*total)/(1.0*len));
    }
    else {
        return 0;
    }
}

export function isNullUndefBlank (x) {
    if (x === null || x === undefined || x === ''){
        return true;
    } else {
        return false;
    }
}